.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scene {
  height: 30vw;
  width: 30vw;
  /* position: absolute; */
  bottom: 35vh;
}

.spotlight {
  background-color: white;
}

.background {
  z-index: -9999;
  background: rgba(255,255,255,1);
  /* background: linear-gradient(0deg, rgba(0,0,0,1) 2%, rgba(210,210,210,1) 33%, rgba(245,245,245,1) 50%, rgba(255,255,255,1) 67%, rgba(255,255,255,1) 100%); */
  /* background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(223,223,223,1) 12%, rgba(255,255,255,1) 100%); */
  /* background: linear-gradient(0deg, rgba(130,121,114,1) 0%, rgba(255,242,232,1) 20%, rgba(255,255,255,1) 100%); */
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(196,254,255,1) 23%, rgba(255,255,255,1) 100%); */
}

.theNFT {
  height: 30vw;
  width: 30vw;
  position: absolute;
  bottom: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;

}

.infoText {
  opacity: 1;
  animation-name: infoTextFade;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s
}

.noCommaVideo {
  -webkit-appearance: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}


@keyframes infoTextFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 601px) {
  h3 {
    /* font-size: 80px; */
  }
  
  div.noComma {
    height: 10vw;
    width:  10vw;
  }
  
  img.noCommaSign {
    width: 45vw;
  }

  img.notAComma {
    width: 25vw;
  }

  div.nft {
    height: 30vw;
    width:  30vw;
    bottom: 35vh;
  }

  div.floatingInfo {
    width: 20vw;
  }

}

@media screen and (max-width: 600px) {
  h3 {
    /* font-size: 18px; */
  }

  div.noComma {
    /* height: 15vw;
    width:  15vw; */
  }

  img.noCommaSign {
    width: 65vw;
  }

  img.notAComma {
    width: 40vw;
  }

  div.nft {
    height: 30vw;
    width:  30vw;
    bottom: 35vh;
  }

  div.floatingInfo {
    width: 30vw;
  }

}